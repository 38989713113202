<template>
  <div>
    <span v-if="roomType" style="padding: 0 16px">
      <span v-if="roomType.facility">{{
        roomType.facility.name
      }}</span>
      <span v-if="contract.mwPwWeekNumber">{{
        contract.mwPwWeekNumber
      }}</span>
      <span v-if="contract.mwPwWeekNumber">{{ '週' }}</span>
      <span v-if="roomType.name">{{
        roomType.name
      }}</span>
    </span>
    <span v-else>
      <div style="height: 36px; line-height: 36px"></div>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    contract: Object
  },
  computed: {
    roomType () {
      return this.contract.mwPwRoomType ?? this.contract.vmRoomType
    }
  }
}
</script>